/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import Post from '../components/Blog/Post';
import Card from '../components/Card/Card';
import Pagination from '../components/Blog/Pagination';

export const pageQuery = graphql`
  query AuthorByIdQuery($id: String!, $skip: Int!, $limit: Int!) {
    wpUser(id: { eq: $id }) {
      name
      description
      avatar {
        url
      }
      seo {
        title
        metaDesc
        schema {
          raw
        }
      }
    }

    allWpPost(
      filter: { author: { node: { id: { eq: $id } } } }
      sort: { fields: [date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110)
              }
            }
          }
        }

        author {
          node {
            uri
            name
          }
        }

        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;

const BlogAuthorPage = ({
  data,
  pageContext: { previousPagePath, nextPagePath },
}) => {
  const author = data.wpUser;
  const posts = data.allWpPost.nodes;

  return (
    <>
      {!!author.seo && (
        <Seo
          title={author.seo.title}
          description={author.seo.metaDesc}
          schema={author.seo.schema.raw}
        />
      )}

      <BrandGrid />

      <Container>
        <Row>
          <Col>
            <header className="ui-header mb-5">
              <h1 className="h1--center text-center">Author: {author.name}</h1>
            </header>
          </Col>
        </Row>
        <Row className="justify-content-lg-between">
          <Col xl={8}>
            {posts.map((post) => {
              const featuredImage = {
                image: getImage(post.featuredImage?.node?.localFile),
                alt: post.featuredImage?.node?.altText || ``,
              };

              return (
                <Fragment key={post.uri}>
                  <Post
                    uri={post.uri}
                    title={post.title}
                    featuredImage={featuredImage}
                    date={post.date}
                    author={post.author.node}
                    excerpt={post.excerpt}
                    categories={post.categories.nodes}
                  />
                  <hr className="no-border my-5" />
                </Fragment>
              );
            })}

            {(previousPagePath || nextPagePath) && (
              <Pagination previous={previousPagePath} next={nextPagePath} />
            )}
          </Col>
          <Col xl={3} as="aside" className="mt-5 mt-xl-0">
            {/* Author box */}
            <Card>
              <Row className="align-items-center">
                {!!author.avatar && (
                  <Col className="col-auto">
                    <img
                      src={author.avatar.url}
                      alt={author.name}
                      width={40}
                      height={40}
                      className="ui-avatar--image"
                    />
                  </Col>
                )}
                <Col className="col-auto ps-0">
                  <h5 className="mb-0">{author.name}</h5>
                </Col>
              </Row>
              {author.description && (
                <Row>
                  <Col>
                    <p className="font-size-sm mb-0 mt-3">
                      {author.description}
                    </p>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogAuthorPage;
